import React, { useState } from 'react'

import Popup from 'reactjs-popup'

import Input from '../input'

import Form from '../form'

import Button from '../button'

import styles from './style.module.scss'

import Logo from '../logo'

import { TiDelete, TiLockClosed } from 'react-icons/ti'

const storage = typeof sessionStorage === 'undefined' ? {} : sessionStorage

export default ({ children, href }) => {
    const [email, setEmail] = useState(storage.email)
    const [errorMessage, setErrorMessage] = useState(null)
    const [countDownStarted, setCountDownStarted] = useState(false)

    return (
        <Popup
            trigger={children}
            modal
            closeOnDocumentClick
            contentStyle={{ border: 0, width: 'initial' }}
        >
            {close => (
                <Form
                    className={styles.modal}
                    noSuccessMessage
                    requiredFields={['your_email']}
                    errorMessageClass={styles.formErrorMessage}
                >
                    <TiDelete className={styles.close} onClick={close} />
                    <Logo className={styles.logo} />
                    <p>Enter your email to download now</p>

                    <Input
                        label="Your email"
                        required
                        className={countDownStarted && styles.hidden}
                        onChange={text => {
                            setEmail(text)
                        }}
                    />

                    {countDownStarted && !errorMessage && (
                        <p className={styles.success}>
                            Thank you for submitting your email, your download
                            will start soon!
                        </p>
                    )}
                    {errorMessage && (
                        <p className={styles.errorMessage}>
                            Download unavailable, but don't worry we will email
                            you once we get it!
                        </p>
                    )}
                    {!countDownStarted && (
                        <Button
                            className={styles.btn}
                            secondary
                            onClick={() => {
                                if (
                                    !email ||
                                    !email.length ||
                                    !validateEmail(email)
                                ) {
                                    return false
                                }
                                storage.email = email
                                setCountDownStarted(true)
                                if (!href) {
                                    setErrorMessage(
                                        "Download unavailable, but don't worry we will email you once we get it!"
                                    )
                                }
                                setTimeout(function() {
                                    if (typeof window !== 'undefined') {
                                        if (href) {
                                            window.location = href
                                        }
                                        close()
                                    }
                                }, 3000)
                            }}
                        >
                            Download now
                        </Button>
                    )}

                    <p className={styles.statement}>
                        <TiLockClosed />
                        Your details will be treated with respect in compliance
                        with our privacy policy.
                    </p>
                </Form>
            )}
        </Popup>
    )
}

function validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}
