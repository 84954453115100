import React from 'react'

import EnterYourEmailPopup from '../enter-your-email-popup'

const storage = typeof sessionStorage === 'undefined' ? {} : sessionStorage

export default ({ children, ...props }) => {
    return storage.email && props.href ? (
        <a
            href={props.href}
            target="_blank"
            {...props}
            rel="noopener noreferrer"
        >
            {children}
        </a>
    ) : (
        <EnterYourEmailPopup {...props}>
            <div {...props}>{children}</div>
        </EnterYourEmailPopup>
    )
}
