import React, { Fragment, useState } from 'react'

import styles from './style.module.scss'

import Form from '../form'

import cn from 'classnames'

import PropertyScore from '../property-score'

import { FeaturedImageWithContent } from '../featured-image'

import Button from '../button'

import Image from 'gatsby-image'

import Input from '../input'

import { Link } from 'gatsby'

import { TiHome, TiInfoLarge, TiTick, TiStar } from 'react-icons/ti'

import { FaAlignLeft } from 'react-icons/fa'

import { FiFileText } from 'react-icons/fi'

import SocialShares from '../social-shares'

import BrochureImage from './brochure-image'

import ProtectedLink from '../protected-link'

import DefaultThumbnail from './default-thumbnail'

import PhoneInput from '../phone-input'

const RANKS = {
    'A+': 'Excellent',
    A: 'Very Good',
    'B+': 'Good',
    B: 'Above Average',
    C: 'Average',
    D: 'Below Average'
}

const AMENITIES = {
    'Swimming Pool': require('../../images/amenities/swimming_pool.svg'),
    'Covered Parking': require('../../images/amenities/covered_parking.svg'),
    '24/7 Security': require('../../images/amenities/security.svg'),
    Gym: require('../../images/amenities/gym.svg'),
    'Running Track': require('../../images/amenities/running_track.svg'),
    'Recreational Zone': require('../../images/amenities/recreational_zone.svg'),
    'Retail Centre': require('../../images/amenities/retail_center.svg'),
    'Super Market': require('../../images/amenities/super_market.svg'),
    'Kid’s School': require('../../images/amenities/kids_school.svg'),
    'Community Hall': require('../../images/amenities/community_hall.svg'),
    Park: require('../../images/amenities/park.svg'),
    'Kid’s Play Area': require('../../images/amenities/kids_play_area.svg')
}

function sanitize(name) {
    let sanitized = name.replace(/\W/g, '').toLowerCase()
    return sanitized
}

function getProjectDetails(data) {
    let project = data.allWordpressWpProject.edges[0].node
    let developer = data.allWordpressWpDevelopers.edges.find(
        edge => project.developers.indexOf(edge.node.wordpress_id) > -1
    ).node
    let area = data.allWordpressWpAreas.edges.find(
        edge => project.areas.indexOf(edge.node.wordpress_id) > -1
    ).node

    return {
        Developer: (
            <Link to={'/developer/' + developer.slug}>{developer.name}</Link>
        ),
        Community: <Link to={'/area/' + area.slug}>{area.name}</Link>,
        'Registration No': project.acf.registration_no,
        'Expected Service Charge': project.acf.expected_service_charge,
        Location: project.acf.location,
        'Property Type': project.acf.property_type,
        'Property Title Type': project.acf.property_title_type,
        Handover: project.acf.handover
    }
}

function isAmenityActive(key, project) {
    return (
        project.acf.amenities &&
        project.acf.amenities.find(
            amenity => sanitize(key) === sanitize(amenity)
        )
    )
}

const SingleProject = ({ name, data }) => {
    let project = data.allWordpressWpProject.edges[0].node

    const [expanded, setExpanded] = useState(false)
    return (
        <Fragment>
            <FeaturedImageWithContent
                className={styles.featuredImage}
                title={project.title}
                alt={project.featured_media && project.featured_media.alt_text}
                fluid={
                    project.featured_media &&
                    project.featured_media.localFile &&
                    project.featured_media.localFile.childImageSharp &&
                    project.featured_media.localFile.childImageSharp.fluid
                }
            >
                <div className={cn(styles.card, styles.gradeCard)}>
                    <h3 className={styles.title}>
                        <TiStar /> Grade
                    </h3>
                    <div className={styles.grades}>
                        <div className="row">
                            <div className="col-xs-6">
                                {project.acf.investor_grade || 'A+'}
                            </div>
                            <div className="col-xs-6">Investor grade</div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6">
                                {project.acf.end_user_grade || 'B+'}
                            </div>
                            <div className="col-xs-6">End user grade</div>
                        </div>
                    </div>
                </div>
                <div className={styles.meaning}>
                    {Object.keys(RANKS).map(key => (
                        <div className={styles.item} key={key}>
                            <div>{key}</div>
                            <div>{RANKS[key]}</div>
                        </div>
                    ))}
                </div>
            </FeaturedImageWithContent>
            <div className={styles.singleProject}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8">
                            <div className={styles.card}>
                                <h3>
                                    <TiHome />
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: project.title + ' Facts'
                                        }}
                                    />
                                </h3>
                                <div className={styles.customFieldsRow}>
                                    {Object.keys(getProjectDetails(data)).map(
                                        key => (
                                            <div
                                                key={key}
                                                className={cn(
                                                    'row',
                                                    styles.customField
                                                )}
                                            >
                                                <div className="col-xs-6">
                                                    <span
                                                        className={
                                                            styles.customFieldKey
                                                        }
                                                    >
                                                        {key}
                                                    </span>
                                                </div>
                                                <div className="col-xs-6">
                                                    <span
                                                        className={
                                                            styles.customFieldValue
                                                        }
                                                    >
                                                        {getProjectDetails(
                                                            data
                                                        )[key] || '---'}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                    <SocialShares />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <h3 className={styles.title}>
                                    <FaAlignLeft /> Property Score
                                </h3>
                                <div className={styles.body}>
                                    <PropertyScore project={project} />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <h3 className={styles.title}>
                                    <TiTick />{' '}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                project.title + ' Description'
                                        }}
                                    />
                                </h3>
                                <div className={styles.body}>
                                    <div
                                        className={cn(
                                            styles.description,
                                            expanded && styles.expanded
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: project.content
                                        }}
                                    />
                                    <div
                                        className={styles.viewMore}
                                        onClick={() =>
                                            setExpanded(oldV => !oldV)
                                        }
                                    >
                                        {expanded ? 'View less' : 'View more'}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <h3>
                                    <TiHome />
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: project.title + ' Amenities'
                                        }}
                                    />
                                </h3>
                                <div className={styles.body}>
                                    <div className={styles.amenities}>
                                        {Object.keys(AMENITIES).map(key => {
                                            const Item = AMENITIES[key]

                                            return (
                                                <div
                                                    className={cn(
                                                        styles.amenity,
                                                        styles[sanitize(key)],
                                                        isAmenityActive(
                                                            key,
                                                            project
                                                        ) && styles.active
                                                    )}
                                                    key={key}
                                                >
                                                    <Item />
                                                    <span>{key}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <ProtectedLink
                                        href={
                                            project.acf.floor_plan &&
                                            project.acf.floor_plan.source_url
                                        }
                                        target="_blank"
                                        className={styles.pdfDownload}
                                    >
                                        <FiFileText />
                                        <div className={styles.pdfRow}>
                                            <div
                                                className={styles.title}
                                                dangerouslySetInnerHTML={{
                                                    __html: project.title
                                                }}
                                            />
                                            <div className={styles.downloadNow}>
                                                Download Floor Plan
                                            </div>
                                        </div>
                                    </ProtectedLink>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <ProtectedLink
                                        target="_blank"
                                        href={
                                            project.acf.payment_plan &&
                                            project.acf.payment_plan.source_url
                                        }
                                        className={styles.pdfDownload}
                                    >
                                        <FiFileText />
                                        <div className={styles.pdfRow}>
                                            <div
                                                className={styles.title}
                                                dangerouslySetInnerHTML={{
                                                    __html: project.title
                                                }}
                                            />
                                            <div className={styles.downloadNow}>
                                                Download Payment Plan
                                            </div>
                                        </div>
                                    </ProtectedLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <Form
                                className={styles.card}
                                requiredFields={['name', 'mobile', 'email']}
                            >
                                <h3 className={styles.title}>
                                    <TiInfoLarge /> Get more information
                                </h3>
                                <div className={styles.body}>
                                    <Input
                                        label="Name"
                                        placeholder="Your name"
                                        required
                                    />
                                    <PhoneInput
                                        label="Mobile"
                                        placeholder="Your mobile"
                                        required
                                    />
                                    <Input
                                        label="Email"
                                        placeholder="Your email"
                                        required
                                    />
                                    <div className={styles.options}>
                                        <Input
                                            label="Get the rera construction report"
                                            type="checkbox"
                                        />
                                        <Input
                                            label="Get more information"
                                            type="checkbox"
                                        />
                                        <Input
                                            label="Be the first to know about this community"
                                            type="checkbox"
                                        />
                                        <Input
                                            label="List property in this community"
                                            type="checkbox"
                                        />
                                        <Input
                                            label="Sell my property in this community"
                                            type="checkbox"
                                        />
                                    </div>

                                    <Button className={styles.submit}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                            <ProtectedLink
                                href={
                                    project.acf.brochure &&
                                    project.acf.brochure.source_url
                                }
                                className={styles.downloadBrochure}
                            >
                                <BrochureImage
                                    className={styles.brochureImage}
                                />
                                <h3>Download Brochure</h3>
                            </ProtectedLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            {project.acf.gallery_1 &&
                            project.acf.gallery_1.localFile &&
                            project.acf.gallery_1.localFile.childImageSharp ? (
                                <Fragment>
                                    <Image
                                        alt={project.acf.gallery_1.title}
                                        fluid={
                                            project.acf.gallery_1.localFile
                                                .childImageSharp.fluid
                                        }
                                        className={styles.image}
                                    />
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                project.acf.gallery_1.title ||
                                                project.title
                                        }}
                                        className={styles.imageTitle}
                                    />
                                </Fragment>
                            ) : (
                                <DefaultThumbnail className={styles.image} />
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            {project.acf.gallery_2 &&
                            project.acf.gallery_2.localFile &&
                            project.acf.gallery_2.localFile.childImageSharp ? (
                                <Fragment>
                                    <Image
                                        alt={project.acf.gallery_2.title}
                                        fluid={
                                            project.acf.gallery_2.localFile
                                                .childImageSharp.fluid
                                        }
                                        className={styles.image}
                                    />
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                project.acf.gallery_2.title ||
                                                project.title
                                        }}
                                        className={styles.imageTitle}
                                    />
                                </Fragment>
                            ) : (
                                <DefaultThumbnail className={styles.image} />
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            {project.acf.gallery_3 &&
                            project.acf.gallery_3.localFile &&
                            project.acf.gallery_3.localFile.childImageSharp ? (
                                <Fragment>
                                    <Image
                                        alt={project.acf.gallery_3.title}
                                        fluid={
                                            project.acf.gallery_3.localFile
                                                .childImageSharp.fluid
                                        }
                                        className={styles.image}
                                    />
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                project.acf.gallery_3.title ||
                                                project.title
                                        }}
                                        className={styles.imageTitle}
                                    />
                                </Fragment>
                            ) : (
                                <DefaultThumbnail className={styles.image} />
                            )}
                        </div>
                        {project.acf.gallery_4 &&
                        project.acf.gallery_4.localFile &&
                        project.acf.gallery_5 &&
                        project.acf.gallery_5.localFile &&
                        project.acf.gallery_6 &&
                        project.acf.gallery_6.localFile ? (
                            <Fragment>
                                <div className="col-xs-12 col-sm-4">
                                    {project.acf.gallery_4 ? (
                                        <Fragment>
                                            <Image
                                                fluid={
                                                    project.acf.gallery_4
                                                        .localFile
                                                        .childImageSharp.fluid
                                                }
                                                className={styles.image}
                                            />
                                            <h3
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        project.acf.gallery_4
                                                            .title ||
                                                        project.title
                                                }}
                                                className={styles.imageTitle}
                                            />
                                        </Fragment>
                                    ) : (
                                        <DefaultThumbnail
                                            className={styles.image}
                                        />
                                    )}
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    {project.acf.gallery_5 ? (
                                        <Fragment>
                                            <Image
                                                fluid={
                                                    project.acf.gallery_5
                                                        .localFile
                                                        .childImageSharp.fluid
                                                }
                                                className={styles.image}
                                            />
                                            <h3
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        project.acf.gallery_5
                                                            .title ||
                                                        project.title
                                                }}
                                                className={styles.imageTitle}
                                            />
                                        </Fragment>
                                    ) : (
                                        <DefaultThumbnail
                                            className={styles.image}
                                        />
                                    )}
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    {project.acf.gallery_6 ? (
                                        <Fragment>
                                            <Image
                                                fluid={
                                                    project.acf.gallery_6
                                                        .localFile
                                                        .childImageSharp.fluid
                                                }
                                                className={styles.image}
                                            />
                                            <h3
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        project.acf.gallery_6
                                                            .title ||
                                                        project.title
                                                }}
                                                className={styles.imageTitle}
                                            />
                                        </Fragment>
                                    ) : (
                                        <DefaultThumbnail
                                            className={styles.image}
                                        />
                                    )}
                                </div>
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SingleProject
