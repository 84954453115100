import React from 'react'

import styles from './style.module.scss'

import { Project } from '../projects'

const Projects = ({ data }) => {
    return data.relatedProjects ? (
        <div className={styles.projects}>
            <h3 className={styles.heading}>Related projects</h3>
            <div className="wrapper-fluid">
                <div className="row">
                    {data.relatedProjects.edges.map(edge => (
                        <div
                            className="col-xs-12 col-sm-6 col-md-4"
                            key={edge.node.slug}
                        >
                            <Project data={data} edge={edge} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null
}

export default Projects
