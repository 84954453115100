import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import SingleProject from '../components/single-project'

import RelatedProjects from '../components/related-projects'

import SeoSearch from '../components/seo-search'

export default ({ data }) => {
    const project = data.allWordpressWpProject.edges[0].node
    return (
        <Layout
            marketTicker
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Projects'
                },
                {
                    title: project.title
                }
            ]}
        >
            <SEO
                title={project.yoast_meta.yoast_wpseo_title}
                description={project.yoast_meta.yoast_wpseo_metadesc}
            />

            <SingleProject data={data} />

            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <RelatedProjects data={data} />
                    </div>
                    <div className="col-xs-12">
                        <SeoSearch data={data} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!, $areas: [Int!], $related: [Int!]) {
        allWordpressWpProject(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    slug
                    title
                    content
                    areas
                    developers
                    project_category
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                    acf {
                        investor_grade
                        end_user_grade
                        handover
                        location
                        property_type
                        property_title_type
                        expected_service_charge
                        registration_no
                        value_for_money
                        location_and_connectivity
                        rental_yield_expectation
                        capital_growth_expectation
                        amenities

                        gallery_1 {
                            title
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        gallery_2 {
                            title
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        gallery_3 {
                            title
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        gallery_4 {
                            title
                        }
                        gallery_5 {
                            title
                        }
                        gallery_6 {
                            title
                        }
                        brochure {
                            source_url
                        }
                        payment_plan {
                            source_url
                        }
                        floor_plan {
                            source_url
                        }
                    }
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpSeoSearch(filter: { areas: { in: $areas } }) {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpDevelopers {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        relatedProjects: allWordpressWpProject(
            filter: { wordpress_id: { in: $related } }
        ) {
            edges {
                node {
                    slug
                    title
                    content
                    areas
                    developers
                    project_category
                    acf {
                        investor_grade
                        end_user_grade
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
