import React from 'react'

import styles from './style.module.scss'

const SCORES = {
    'Value for Money': 95,
    'Location & Connectivity': 90,
    'Rental Yield Expectation': 85,
    'Capital Growth Expectation': 92
}

function getScore(project) {
    return {
        'Value for Money': project.acf.value_for_money || 95,
        'Location & Connectivity': project.acf.location_and_connectivity || 90,
        'Rental Yield Expectation': project.acf.rental_yield_expectation || 85,
        'Capital Growth Expectation':
            project.acf.capital_growth_expectation || 92
    }
}
const PropertyScore = ({ project }) => (
    <div className={styles.scores}>
        {Object.keys(SCORES).map(s => (
            <div className={styles.score} key={s}>
                <span>{s}</span>
                <div
                    className={styles.overlay}
                    style={{ width: `${getScore(project)[s]}%` }}
                >
                    <span>{getScore(project)[s]}%</span>
                </div>
            </div>
        ))}
    </div>
)

export default PropertyScore
