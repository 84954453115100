import React from 'react'

import { Twitter, Facebook, Linkedin, Mail } from 'react-social-sharing'

import styles from './style.module.scss'

function getWindow() {
    if (typeof window !== 'undefined') {
        return window
    }
    return {
        location: ''
    }
}

export default () => (
    <div className={styles.socialShares}>
        <div>Share on </div>
        <Twitter link={getWindow().location} />
        <Facebook link={getWindow().location} />
        <Mail link={getWindow().location} />
        <Linkedin link={getWindow().location} />
    </div>
)
